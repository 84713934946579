
// export default HomePost;
import React, { useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import "./HomePagePost.css";
import { Firebase } from "../../firebase/config";
import CircleLoading from "../LoadingsPages/CircleLoading";
import PostCards from "../PostCardScrap/PostCards";
import Loogin from "../Login/Login"
import { useAuth } from '../../Auth/AuthContext copy';
function HomePost() {
  const [all, setAll] = useState([]);
  const [allLastKey, setAllLastKey] = useState("");
  const [allLoading, setAllLoading] = useState(true);
  const { currentUser, setModal } = useAuth();
  const navigate = useNavigate(); // Use this for programmatic navigation

  // Function to open the login modal
  const openLogin = () => {
    setModal({ isOpen: true, title: 'Login', content: <Loogin /> });
  };

  // Function to handle the "View More" click
  const handleViewMoreClick = () => {
    if (currentUser && currentUser.emailVerified) {
      navigate('/viewmore/all'); // Redirect to the "View More" page
    } else if (currentUser && !currentUser.emailVerified) {
      alert('Please verify your email first.');
    } else {
      openLogin(); // Show login modal if the user is not logged in
    }
  };
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const snapshot = await Firebase.firestore()
          .collection("products")
          .orderBy("time", "desc")
          .limit(20)
          .get();

        const filterpost = snapshot.docs.map((product) => ({
          id: product.id,
          ...product.data(),
        }));

        const lastKey = snapshot.docs[snapshot.docs.length - 1];
        setAll(filterpost);
        setAllLastKey(lastKey);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setAllLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const allPosts = all.map((product, index) => (
    <div className="postescards" key={index}>
      <PostCards product={product} index={index} />
    </div>
  ));

  return (
    <div className="viewMore">
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        fontWeight="bold"
        className="productHeading"
      >
        List of Products
      </Typography>
      {allLoading ? (
       <div className="loadingContainer">
            <CircleLoading />
             </div>
      ) : (
        <div className="cardes">
          <div className="allpostdone">{allPosts}</div>
          {all.length > 0 && (
            <Box textAlign="center" mt={3}>
                <Button variant="contained" className="viewMoreButton" onClick={handleViewMoreClick}>
                  View More  
                </Button>
             
            </Box>
          )}
        </div>
      )}
    </div>
  );
}

export default HomePost;
