import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import { RiAdvertisementFill } from "react-icons/ri";
import { IoIosContact } from "react-icons/io";
import Logout from '@mui/icons-material/Logout';
import { Button } from '@mui/material';
import { Lock } from '@mui/icons-material';
import Profile from './Profile';
import AccountSettings from '../settings/AccountSettings';
import { useNavigate } from "react-router";
import "./Header.css";
import { useAuth } from "../../Auth/AuthContext copy";
import Loogin from "../Login/Login"
import LogoMain from "../../assets/logoapp";
import SearchIcon from '@mui/icons-material/Search';
function Header() {

  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  const handleSearchClick = () => {
    if (currentUser && currentUser.emailVerified) {
      navigate(`/search/${query.toLowerCase()}`);
    } else if (currentUser && !currentUser.emailVerified) {
      alert('Please verify your email first.');
    } else {
      openLogin(); // Show login modal if the user is not logged in
    }
  };
  const { currentUser, setModal, logout, setAlert } = useAuth();
  const openLogin = () => {
    setModal({ isOpen: true, title: 'Login', content: <Loogin /> });
  };
  const handleClickCreatepost = () => {
    if (currentUser && currentUser.emailVerified) {
      navigate('/createpost'); // Redirect to the "View More" page
    } else if (currentUser && !currentUser.emailVerified) {
      alert('Please verify your email first.');
    } else {
      openLogin(); // Show login modal if the user is not logged in
    }
  };
  
  const handleNavigation = (pathname) => {
    if (currentUser && currentUser.emailVerified) {
      navigate(pathname);
    } else if (currentUser && !currentUser.emailVerified) {
      alert('Please verify your email first.');
    } else {
      openLogin(); // Show login modal if the user is not logged in
    }
  };
  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      setAlert({
        isAlert: true,
        severity: 'error',
        message: error.message,
        timeout: 8000,
        location: 'main',
      });
      console.log(error);
    }
  };
  

  const re = () => {
    window.location.reload();
  }

  const styles = {
    searchBarContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      padding: '0 8px',
      border: '1px solid #4CAF50', // Change border color to green
      borderRadius: '4px 0 0 4px', // Rounded corners on the left side
      backgroundColor: 'white',
      outline: 'none', // Optional: Removes outline on focus
    },
    searchButton: {
      backgroundColor: '#4CAF50', // Green background
      borderRadius: '0 4px 4px 0', // Rounded corners on the right side
      padding: '8px',
    },
  };
  return (
    <div className="mainstart">
      <div className="mainChildDiv">
        <div className="brandName">
        <a href="/" title="" >
         <LogoMain className="log0height" />
          </a>
        </div>
        
        <div className="searbarbutton">
        <div style={styles.searchBarContainer}>
        <input
        type="text"
        className="searchbar"
        placeholder="What are you looking for?"
        onChange={handleSearch}
        value={query}
        style={styles.input}
        />

       <IconButton 
        style={styles.searchButton} 
        onClick={handleSearchClick} // Call the function on button click
      >
        <SearchIcon style={{ color: 'white' }} />
      </IconButton>
      </div>
           

          <div className="oplastic" >
            <div className="linkbuttons">
            <span onClick={() => handleNavigation('/viewmore/Plastic')}>Plastic</span>
            </div>
          </div>
          <div className="ometal" >
            <div className="linkbuttons">
            <span onClick={() => handleNavigation('/viewmore/Metal')}>Metal</span>
            </div>
          </div>
          <div className="ocopper">
            <div className="linkbuttons">
            <span onClick={() => handleNavigation('/viewmore/Copper')}>Copper</span>
            </div>
          </div>
          <div className="osteel">
            <div className="linkbuttons">
            <span onClick={() => handleNavigation('/viewmore/Steel')}>Steel</span>
            </div>
          </div>
          <div className="oaluminum">
            <div className="linkbuttons">
            <span onClick={() => handleNavigation('/viewmore/Aluminium')}>Aluminium</span>
            </div>
          </div>
        </div>


        <div className="loginPage">
          <Box sx={{}}>
            {!currentUser ? (
              <Button 
              className="signin-button"
              startIcon={<Lock />} onClick={openLogin}>
                Login
              </Button>
            ) : (
              <Tooltip title="Account settings">
                <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                  <Avatar
                    sx={{ width: 40, height: 40 }}
                    src={currentUser?.photoURL}
                  >
                    {currentUser?.displayName?.charAt(0)?.toUpperCase() ||
                      currentUser?.email?.charAt(0)?.toUpperCase()}
                  </Avatar>
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() =>
                setModal({
                  isOpen: true,
                  title: 'Update Profile',
                  content: <Profile />,
                })
              }
            >
              <Avatar src={currentUser?.photoURL} /> Profile
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() =>
                setModal({
                  isOpen: true,
                  title: 'Account Settings',
                  content: <AccountSettings />,
                })
              }
            >
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <Link style={{textDecoration: 'none'}} to={{
              pathname: `/userpost/${currentUser?.uid}`,
            }} >
              <MenuItem style={{ color: "black" }} >
                <ListItemIcon>
                  <RiAdvertisementFill fontSize="large" />
                </ListItemIcon>
                Your Post
              </MenuItem>
            </Link>
            <Link style={{textDecoration: 'none'}} to={{
              pathname: `/contact`,
            }} >
              <MenuItem style={{ color: "black" }} >
                <ListItemIcon>
                  <IoIosContact fontSize="large" />
                </ListItemIcon>
                Help & Contact
              </MenuItem>
            </Link>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
        <div className="sellbuttondiv">
            <Button variant="contained" className="sellbutton" onClick={handleClickCreatepost} >Post Ad</Button>
        </div>
      </div>
    </div>
  );
}

export default Header;
