import React from 'react';
import "./FooterStyles.css"
import { FaFacebookF, FaXTwitter, FaInstagram, FaYoutube } from "react-icons/fa6";
import { Link,useNavigate } from "react-router-dom"
import SVGComponent from '../../assets/applyplaystorlog';
import PlaystoreAndroid from '../../assets/playstore2';
import { useAuth } from "../../Auth/AuthContext copy";
import Loogin from "../Login/Login"
import { withTheme } from '@emotion/react';
function Footer() {
  const navigate = useNavigate();
  const { currentUser, setModal, logout, setAlert } = useAuth();
  const openLogin = () => {
    setModal({ isOpen: true, title: 'Login', content: <Loogin /> });
  };
  const handleNavigation = (pathname) => {
    if (currentUser && currentUser.emailVerified) {
      navigate(pathname);
    } else if (currentUser && !currentUser.emailVerified) {
      alert('Please verify your email first.');
    } else {
      openLogin(); // Show login modal if the user is not logged in
    }
  };
  return (
    <div className="fotterstart">
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-3 item">
              <h3>Products</h3>
              <ul>
                 <div className="linkbuttons" >
                 <span onClick={() => handleNavigation('/viewmore/Plastic')}>Plastic</span>
                 </div>
                  <div className="linkbuttons">
                  <span onClick={() => handleNavigation('/viewmore/Metal')}>Metal</span>
                  </div>
                  <div className="linkbuttons">
                  <span onClick={() => handleNavigation('/viewmore/Copper')}>Copper</span>
                   </div>
                   <div className="linkbuttons">
                    <span onClick={() => handleNavigation('/viewmore/Steel')}>Steel</span>
                    </div>
                    <div className="linkbuttons">
                      <span onClick={() => handleNavigation('/viewmore/Aluminium')}>Aluminium</span>
                     </div>
                     <div className="linkbuttons">
                      <span onClick={() => handleNavigation('/viewmore/Others')}>Others</span>
                     </div>
               
                     </ul>
            </div>
            <div className="col-sm-6 col-md-3 item">
              <div className='abouttop'><h3>About</h3></div>
              
              <ul>
              <li> <Link className="linkbuttons" style={{ textDecoration: 'none' }} to=
                  {{ pathname: "/about" }}
                >
                  <span>DropScrap</span>
                </Link></li>
              </ul>
            </div>
            <div className="col-md-6 item text">
                            
                            <div className='abouttop'><h3>Download Mobile Apps</h3></div>
                            <a href="https://play.google.com/store/apps/details?id=com.dropscrap.dropscrapapp&pli=1" title="DropScrap Android App" ><PlaystoreAndroid style={{height:"41px"}} /></a>
                            <a href="" title="DropScrap IOS Application" ><SVGComponent style={{height:"41px"}} /></a>

                        </div>

            <div className="col item social">
              <a href="https://www.facebook.com/profile.php?id=61558798318333&mibextid=ZbWKwL"><i><FaFacebookF /></i></a>
              <a href="https://twitter.com/dropscraps"><i><FaXTwitter /></i></a>
              <a href="https://www.instagram.com/dropscrap?igsh=ejRlbWI3enN3NXc5"><i><FaInstagram /></i></a>
              <a href="https://www.youtube.com/@dropscrap"><i><FaYoutube /></i></a>
            </div>
          </div>
          <div className="privacypolices">
            <Link style={{ color: 'white', opacity: "0.3", fontSize: "13px" }} to={{
              pathname: "/term",
            }}>
              <span>Terms of Service</span>
            </Link>
            <span style={{ color: 'white', opacity: "0.3", fontSize: "13px" }}> | </span>
            <Link style={{ color: 'white', opacity: "0.3", fontSize: "13px" }} to={{
              pathname: "/privacy",
            }}>
              <span>Privacy Policy</span>
            </Link>
          </div>
          <p className="copyright">Copyright © 2024 DropScrap - All Rights Reserved.</p>

        </div>
      </footer>
    </div>
  );
}

export default Footer;
